/*
Author: Kamakhya Pandey (Modified and re-use an existing component)
Created:
Last updated: 02-Feb-2021
Purpose: Authentication Component
Functions:
    User authentication against AzureAD through Cognito
    Receive JWT Tokens (Access Token, ID Token and Refresh Token)
    Store token and expiry information in browser local storage
    Set expiration for new/refresh token
    Fetch token on request from other Components
*/
import cryptoBrowserify from 'crypto-browserify';

const SCOPE = 'phone+email+openid+profile+aws.cognito.signin.user.admin';

const accessToken = window.localStorage.getItem('accessToken');
const idToken = window.localStorage.getItem('idToken');
const refreshToken = window.localStorage.getItem('refreshToken');
const tokenExpiry = window.localStorage.getItem('tokenExpiry');

let tokens = accessToken === null ? null : {
    accessToken,
    idToken,
    refreshToken,
    tokenExpiry
};

const base64URLEncode = str => {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};

const sha256 = buffer => {
    return cryptoBrowserify.createHash('sha256').update(buffer).digest();
}

let storedVerifier = window.localStorage.getItem('verifier');
if (storedVerifier === null) {
    const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
    window.localStorage.setItem('verifier', newVerifier);
    storedVerifier = newVerifier;
}
const verifier = storedVerifier;
const challenge = base64URLEncode(sha256(storedVerifier));
/*
const baseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
*/

const baseUrl = "https://hid-backend-dev.auth.eu-west-1.amazoncognito.com";
const clientId = "7hbb6993khh0mbvakvp47knjl2";
const redirectUri = "https://historicaldatadev.pmicloud.biz/";

/*
const baseUrl = "https://hid-backend-dev.auth.eu-west-1.amazoncognito.com";
const clientId = "7hbb6993khh0mbvakvp47knjl2";
const getUrl = window.location;
const redirectUri = "https://localhost:44331/";
*/

const tokenUrl = `${baseUrl}/oauth2/token`;

export const loginUrl = `${baseUrl}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}`;

export const logoutUrl = `${baseUrl}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;

export const userInfoUrl = `${baseUrl}/oauth2/userInfo`;

console.log("Challenge : " + challenge);
console.log("Base URL : " + baseUrl);
console.log("Client ID : " + clientId);
console.log("Redirect URI : " + redirectUri);
console.log("Token URL : " + tokenUrl);
console.log("Login URL : " + loginUrl);
console.log("Logout URL : " + logoutUrl);

export const refreshTokens = async refreshToken => {
    const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;

    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
    });
    if (!response.ok) {
        throw Error();
    }
    const { access_token, id_token } = await response.json();
    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refreshToken);

    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);

    window.localStorage.setItem('tokenExpiry', expiryDateTime);

    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refreshToken,
        tokenExpiry: expiryDateTime
    };

    console.log(tokens);
};

export const login = async code => {
    const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`;
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
    });
    if (!response.ok) {
        throw Error();
    }
    const { access_token, id_token, refresh_token } = await response.json();

    console.log("Id token : " + id_token);

    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refresh_token);

    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);

    window.localStorage.setItem('tokenExpiry', expiryDateTime);

    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
        tokenExpiry: expiryDateTime
    };
    console.log(tokens);
};

export const logout = async () => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('tokenExpiry');

    tokens = null;
    console.log(tokens);
};

export const getTokens = () => tokens;
